<template>
  <div class="row m-0 justify-content-center">
    <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 p-0 mt-5 mb-3">
      <InitialForm @continue="isShareRewardPopup = true" v-model="baseAmount" />
    </div>
    <transition name="fade">
      <ShareRewardPopup v-if="isShareRewardPopup" @close="isShareRewardPopup = false" @continue="showSadaqahWizard = true" :baseAmount="baseAmount" v-model="saveDetails.totalDailySadaqah" />
    </transition>
    <DailySadaqahWizard v-if="showSadaqahWizard" v-model="saveDetails" @closeWizard="showSadaqahWizard = false" @sadaqahComplete="sadaqahComplete" v-model:paymentMethod="paymentMethod" @save="save" v-model:anonymous="anonymous" :sadaqahUserDonatingType="sadaqahUserDonatingType" v-model:isLoading="isLoading" :wizardSteps="wizardSteps" :sadaqahSavedReturn="sadaqahSavedReturn"/>
    <transition name="fade">
      <CompletePopup v-if="isCompleted" :subscriptionRef="subscriptionRef" @close="sadaqahError" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    InitialForm: defineAsyncComponent(() => import('./views/InitialForm.vue')),
    DailySadaqahWizard: defineAsyncComponent(() => import('./views/DailySadaqahWizard.vue')),
    ShareRewardPopup: defineAsyncComponent(() => import('./views/ShareRewardPopup.vue')),
    CompletePopup: defineAsyncComponent(() => import('./views/CompletePopup.vue'))

  },
  name: 'Daily Sadaqah Setup',
  data () {
    return {
      isShareRewardPopup: false,
      baseAmount: 0,
      showSadaqahWizard: false,
      isLoading: false,
      isCompleted: false,
      paymentMethod: null,
      anonymous: {
        donorType: 'individual',
        isValidNumber: true,
        isCreateDonor: false,
        password: null
      },
      saveDetails: {
        organisationID: null,
        userID: null,
        organisationName: null,
        donorName: null,
        donorSurname: null,
        cellNumber: null,
        email: null,
        address: {
          addressLine1: null,
          town: null,
          country: null,
          postalCode: null
        },
        debitOrderDay: null,
        debitOrderMonth: null,
        bankAccountDetails: {
          bankName: null,
          accountHolderName: null,
          accountNumber: null,
          reference: null,
          branchCode: null
        },
        totalDailySadaqah: null
      },
      sadaqahSavedReturn: null,
      subscriptionRef: null
    }
  },
  computed: {
    ...mapGetters([
      'user', 'donateAs'
    ]),
    sadaqahUserDonatingType () {
      if (this.donateAs) {
        if (this.donateAs.groupID) {
          return 'group'
        } else if (this.user.organisationID) {
          return 'organisation'
        } else {
          return 'individual'
        }
      } else if (this.user) {
        return 'individual'
      } else {
        return this.anonymous.donorType
      }
    },
    wizardSteps () {
      return [
        {
          stepNumber: 1,
          title: 'Donor<br/>Details',
          component: 'DonorDetails'
        },
        {
          stepNumber: 2,
          title: 'Payment<br/>Details',
          component: 'PaymentDetails'
        },
        {
          stepNumber: 3,
          title: 'Complete<br/>Setup',
          component: 'CompleteSetup'
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'saveSadaqahUk'
    ]),
    async save () {
      this.isLoading = true
      try {
        const ret = await this.saveSadaqahUk(this.saveDetails)
        if (ret && ret.status === 200) {
          this.sadaqahSavedReturn = ret
        }
      } catch (error) {
        console.error('ERRR', error)
      }
      this.isLoading = false
    },
    sadaqahComplete (val) {
      this.isLoading = false
      this.subscriptionRef = val.subscriptionReference
      this.isCompleted = true
    },
    sadaqahError () {
      this.isCompleted = false
    }
  }
}
</script>
